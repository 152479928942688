import React from "react"

export default function nav() {
  return (
    <nav className="site-nav">
      <ul>
        <li>
          <a href="#about">About Us</a>
        </li>
        <li>
          <a href="#swatches">Our Range</a>
        </li>
        <li>
          <a href="#history">Our History</a>
        </li>
        <li>
          <a href="#guarantee">Our Quality Guarantee</a>
        </li>
        <li>
          <a href="#downloads">Downloads</a>
        </li>
        <li>
          <a href="#contact">Contact Us</a>
        </li>
        <li>
          <a className="site-nav__phone" href="tel:1800 777 331">
            1800 777 331
          </a>
        </li>
      </ul>
    </nav>
  )
}
