import React from "react"

import iconQualityGuarantee from "../../images/icon-quality-guarantee.png"

export default function Guarantee() {
  return (
    <section id="guarantee" className="quality-guarantee">
      <div className="wrapper quality-guarantee__wrapper">
        <img
          className="quality-guarantee__img"
          src={iconQualityGuarantee}
          alt="Quality Product Guarantee icon"
        />
        <p className="quality-guarantee__text">
          We stand by our product. So much so that we offer a 10&nbsp;year
          Fabric&nbsp;guarantee. It’s peace of mind.
        </p>
      </div>
    </section>
  )
}
