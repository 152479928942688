import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Slider from "react-slick"

const settings = {
  className: "center",
  centerMode: true,
  infinite: true,
  centerPadding: "60px",
  slidesToShow: 7,
  speed: 500,
  autoplay: true,
  dots: false,
  arrows: true,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        arrows: true,
        centerMode: true,
        centerPadding: "40px",
        slidesToShow: 5,
        dots: false,
      },
    },
    {
      breakpoint: 960,
      settings: {
        arrows: true,
        centerMode: true,
        centerPadding: "40px",
        slidesToShow: 3,
        dots: false,
      },
    },
    {
      breakpoint: 460,
      settings: {
        arrows: true,
        centerMode: true,
        centerPadding: "40px",
        slidesToShow: 1,
        dots: false,
      },
    },
  ],
}

const SWATCH_QUERY = graphql`
  query SwatchQuery {
    allMarkdownRemark(sort: { fields: [frontmatter___number] }) {
      edges {
        node {
          id
          frontmatter {
            title
            range
            number
            swatchImage {
              childImageSharp {
                fluid(maxWidth: 360) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

const SwatchList = () => (
  <StaticQuery
    query={SWATCH_QUERY}
    render={data => (
      <>
        <Slider {...settings}>
          {data.allMarkdownRemark.edges.map(edge => (
            <div key={edge.node.id}>
              <Img
                className="swatch__img"
                fluid={edge.node.frontmatter.swatchImage.childImageSharp.fluid}
                alt={edge.node.frontmatter.title}
              />
              <h4 className="swatch__name">{edge.node.frontmatter.title}</h4>
            </div>
          ))}
        </Slider>
      </>
    )}
  />
)

export default SwatchList
