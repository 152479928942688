import React from "react"

import HeroImg from "./hero/HeroImg"

export default function Hero() {
  return (
    <section className="hero">
      <div className="hero__banner">
        <HeroImg />
      </div>
      <div className="wrapper">
        <article className="hero__message">
          <h1 className="hero__title main-heading">
            Dawn. Australia’s & New Zealand’s premium blockout blind fabric.
          </h1>
          <p className="hero__text">
            Backed by an industry leading 10 year warranty and with over 50
            colour options, Australians & New Zealanders have chosen Dawn to
            help protect their home for more than 25 years.
          </p>
        </article>
      </div>
    </section>
  )
}
