import React, { Component } from "react"
import ModalVideo from "react-modal-video"

import VideoBanner from "./video/VideoBanner"

export default class Video extends Component {
  constructor() {
    super()
    this.state = {
      isOpen: false,
    }
    this.openModal = this.openModal.bind(this)
  }

  openModal() {
    this.setState({ isOpen: true })
  }

  render() {
    return (
      <section className="video">
        <h3 className="video__title section-title">
          Protect your greatest asset with quality you can trust
        </h3>
        <div className="video__banner">
          <VideoBanner />
          <button className="video__play-btn">
            <svg
              onClick={this.openModal}
              className="icon__play icon"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              // width="32"
              // height="32"
              viewBox="0 0 32 32"
            >
              <title>play</title>
              <path d="M16 0c-8.837 0-16 7.163-16 16s7.163 16 16 16 16-7.163 16-16-7.163-16-16-16zM16 29c-7.18 0-13-5.82-13-13s5.82-13 13-13 13 5.82 13 13-5.82 13-13 13zM12 9l12 7-12 7z" />
            </svg>
          </button>
        </div>
        <p id="contact" className="video__cta-btn cta-btn">
          <a
            className="cta-btn"
            href="http://uniline.com.au/Document/Detail/51/Contact-Us"
            target="_blank"
            rel="noopener noreferrer"
          >
            Get a quote / contact us
            <svg
              className="cta-btn__icon icon"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
            >
              <title>chevron-with-circle-right</title>
              <path d="M11 10l-2.302-2.506c-0.196-0.198-0.196-0.519 0-0.718 0.196-0.197 0.515-0.197 0.71 0l2.807 2.864c0.196 0.199 0.196 0.52 0 0.717l-2.807 2.864c-0.195 0.199-0.514 0.198-0.71 0-0.196-0.197-0.196-0.518 0-0.717l2.302-2.504zM10 0.4c5.302 0 9.6 4.298 9.6 9.6 0 5.303-4.298 9.6-9.6 9.6s-9.6-4.297-9.6-9.6c0-5.302 4.298-9.6 9.6-9.6zM10 18.354c4.613 0 8.354-3.74 8.354-8.354s-3.741-8.354-8.354-8.354c-4.615 0-8.354 3.74-8.354 8.354s3.739 8.354 8.354 8.354z" />
            </svg>
          </a>
        </p>

        <ModalVideo
          channel="youtube"
          isOpen={this.state.isOpen}
          videoId="iAPVeZkcPLY"
          onClose={() => this.setState({ isOpen: false })}
        />
      </section>
    )
  }
}
