import React from "react"

export default function WhyDawnFabrics() {
  return (
    <section id="reasons" className="reasons wrapper">
      <h3 className="main-heading reasons__title">10 reasons to choose Dawn</h3>
      <ul className="reasons-list">
        <li>
          <h4 className="reasons-list__title">
            Tried and Tested, 100% Blockout, for Over 25 Years
          </h4>
          <p className="reasons-list__desc">
            The highest standard of quality for over quarter of a century. The
            best value for your hard earned money.
          </p>
        </li>
        <li>
          <h4 className="reasons-list__title">Quality Assured</h4>
          <p className="reasons-list__desc">
            Unsurpassed 10 Year Fabric Warranty. Rest assured, have peace of
            mind knowing that you have the best performing and most durable
            blockout fabric available.
          </p>
        </li>
        <li>
          <h4 className="reasons-list__title">Largest Colour Choice</h4>
          <p className="reasons-list__desc">
            With the largest colour range in Australia & New Zealand, of over 50
            shades, we have matched to the most popular paint colours to make
            your colour choice easy.
          </p>
        </li>
        <li>
          <h4 className="reasons-list__title">
            The only Semi-Gloss Blockout Fabric
          </h4>
          <p className="reasons-list__desc">
            Dawn clearly stands out among the rest with its semi-gloss finish,
            which not only makes it easy to clean but adds to its durability.
          </p>
        </li>
        <li>
          <h4 className="reasons-list__title">Easy Cleaning</h4>
          <p className="reasons-list__desc">
            Uniguard&reg; Fabric Protection system and semi-gloss finish makes
            cleaning easy and effective.
          </p>
        </li>
      </ul>
      <ul className="reasons-list">
        <li>
          <h4 className="reasons-list__title">Fully Fade Resistant</h4>
          <p className="reasons-list__desc">
            Guaranteed colours will not fade over the 10 year warranty period.
          </p>
        </li>
        <li>
          <h4 className="reasons-list__title">
            Environment &amp; Home Friendly
          </h4>
          <p className="reasons-list__desc">
            Greenguard&reg; certified to guarantee Air Purity. PVC and Lead
            Free.
          </p>
        </li>
        <li>
          <h4 className="reasons-list__title">Durable and Hard Wearing</h4>
          <p className="reasons-list__desc">
            One of the heaviest &amp; thickest fabrics available in the
            Australian &amp; New Zealand markets today.
          </p>
        </li>
        <li>
          <h4 className="reasons-list__title">
            Anti-cracking & Anti-crazing formulation
          </h4>
          <p className="reasons-list__desc">
            Our patented coating system ensures durability by providing a long
            lasting fabric that will withstand the harsh Australian sun. Unlike
            many blockouts, Dawn is guaranteed not to crack craze or blister.
          </p>
        </li>
        <li>
          <h4 className="reasons-list__title">
            Superior Mould and Mildew Resistance
          </h4>
          <p className="reasons-list__desc">
            Uniline’s XLinked&reg; formulation combined with our Uniguard&reg;
            Fabric Protection system makes Dawn the most resilient fabric
            available today.
          </p>
        </li>
      </ul>
    </section>
  )
}
