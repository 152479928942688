import React, { Component } from "react"

import logo from "../images/dawn-fabrics-logo.svg"
import Nav from "./nav"
// import NavMobile from "./NavMobile"

export default class header extends Component {
  state = {
    on: false,
  }

  // this method just sets the opposite for the on state
  toggle = () => {
    this.setState({
      on: !this.state.on,
    })
  }
  render() {
    return (
      <header className="site-header">
        <div className="wrapper">
          <img className="site-header__brand" src={logo} alt="Dawn Fabrics" />
          <Nav />
          {this.state.on && (
            <nav className="site-nav--mob">
              <button
                className="nav__toggle nav__toggle--close"
                onClick={this.toggle}
              >
                <svg
                  className="icon__close icon"
                  width="28"
                  height="28"
                  viewBox="0 0 20 20"
                >
                  <title>cross</title>
                  <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" />
                </svg>
                <span className="screen-reader-text">Close</span>
              </button>
              <ul>
                <li>
                  <a href="#about" onClick={this.toggle}>
                    About Us
                  </a>
                </li>
                <li>
                  <a href="#swatches" onClick={this.toggle}>
                    Our Range
                  </a>
                </li>
                <li>
                  <a href="#history" onClick={this.toggle}>
                    Our History
                  </a>
                </li>
                <li>
                  <a href="#guarantee" onClick={this.toggle}>
                    Our Quality Guarantee
                  </a>
                </li>
                <li>
                  <a href="#downloads" onClick={this.toggle}>
                    Downloads
                  </a>
                </li>
                <li>
                  <a href="#contact" onClick={this.toggle}>
                    Contact Us
                  </a>
                </li>
                <li>
                  <a
                    className="site-nav__phone--mob"
                    href="tel:1800 777 331"
                    onClick={this.toggle}
                  >
                    1800 777 331
                  </a>
                </li>
              </ul>
            </nav>
          )}
          <button
            className="nav__toggle nav__toggle--open"
            onClick={this.toggle}
          >
            <svg
              className="icon__menu icon"
              width="28"
              height="28"
              viewBox="0 0 20 20"
            >
              <title>menu</title>
              <path d="M16.4 9h-12.8c-0.552 0-0.6 0.447-0.6 1s0.048 1 0.6 1h12.8c0.552 0 0.6-0.447 0.6-1s-0.048-1-0.6-1zM16.4 13h-12.8c-0.552 0-0.6 0.447-0.6 1s0.048 1 0.6 1h12.8c0.552 0 0.6-0.447 0.6-1s-0.048-1-0.6-1zM3.6 7h12.8c0.552 0 0.6-0.447 0.6-1s-0.048-1-0.6-1h-12.8c-0.552 0-0.6 0.447-0.6 1s0.048 1 0.6 1z" />
            </svg>
            <span className="screen-reader-text">Menu</span>
          </button>
        </div>
      </header>
    )
  }
}
