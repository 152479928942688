import React from "react"

import iconTen from "../../images/icon-ten-tick.png"
import iconRainbow from "../../images/icon-rainbow-cloud.png"
import iconTick from "../../images/icon-tick-of-approval.png"
import iconSpray from "../../images/icon-spray.png"

export default function FeatureList() {
  return (
    <section className="wrapper">
      <ul className="feature-list">
        <li>
          <a href="#reasons">
            <img
              className="feature-list__img"
              src={iconTen}
              alt="10 tick icon"
            />
            <h3 className="feature-list__title subheading">
              10 Reasons to Choose Dawn
            </h3>
            <p className="feature-list__text">
              Click here to find out the top ten reasons why Dawn is the best
              blockout blind fabric for your home.
            </p>
          </a>
        </li>
        <li>
          <a href="#reasons">
            <img
              className="feature-list__img"
              src={iconSpray}
              alt="spray bottle icon"
            />
            <h3 className="feature-list__title subheading">
              Easy to Clean and Family Safe
            </h3>
            <p className="feature-list__text">
              Learn how our Uniguard® system makes Dawn simple to clean while
              our Greenguard® guarantee ensures air purity.
            </p>
          </a>
        </li>
        <li>
          <a href="#guarantee">
            <img
              className="feature-list__img"
              src={iconTick}
              alt="tick of approval icon"
            />
            <h3 className="feature-list__title subheading">
              Our 10 Year Promise
            </h3>
            <p className="feature-list__text">
              Dawn is the only blockout blind with a 10 year fabric warranty.
            </p>
          </a>
        </li>
        <li>
          <a href="#swatches">
            <img
              className="feature-list__img"
              src={iconRainbow}
              alt="rainbow icon"
            />
            <h3 className="feature-list__title subheading">
              Colour Your World
            </h3>
            <p className="feature-list__text">
              Choose from Australia’s & New Zealand’s largest colour palette to
              get the perfect look.
            </p>
          </a>
        </li>
      </ul>
    </section>
  )
}
