import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from "../components/sections/Hero"
import FeatureList from "../components/sections/FeatureList"
import Video from "../components/sections/Video"
import Gallery from "../components/sections/Gallery"
import Swatches from "../components/sections/Swatches"
import Guarantee from "../components/sections/Guarantee"
import WhyDawnFabrics from "../components/sections/WhyDawnFabrics"
import About from "../components/sections/About"
import Downloads from "../components/sections/Downloads"

const IndexPage = () => (
  <Layout>
    <SEO
      title="Dawn Fabrics"
      keywords={[
        `dawn`,
        `dawn fabrics`,
        `fabrics`,
        `blinds`,
        `awnings`,
        `shutters`,
        `Australia`,
        `New Zealand`,
      ]}
    />

    <Hero />
    <FeatureList />
    <Video />
    <Gallery />
    <Swatches />
    <Guarantee />
    <WhyDawnFabrics />
    <Downloads />
    <About />
  </Layout>
)

export default IndexPage
