import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Slider from "react-slick"

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  centerPadding: "60px",
  slidesToShow: 2,
  slidesToScroll: 2,
  responsive: [
    {
      breakpoint: 1000,
      settings: {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}

const GALLERY_QUERY = graphql`
  query GalleryQuery {
    allFile(filter: { relativeDirectory: { eq: "gallery" } }) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

const GallerySlider = () => (
  <StaticQuery
    query={GALLERY_QUERY}
    render={data => (
      <>
        <Slider {...settings}>
          {data.allFile.edges.map(edge => (
            <div key={edge.node.id}>
              <Img
                className="swatch__img"
                fluid={edge.node.childImageSharp.fluid}
                alt="Gallery image"
              />
            </div>
          ))}
        </Slider>
      </>
    )}
  />
)

export default GallerySlider
