import React from "react"

import GallerySlider from "./gallery/GallerySlider"

export default function Gallery() {
  return (
    <section id="gallery" className="gallery wrapper">
      <h2 className="section-title gallery__title">
        For every room in your home
      </h2>
      <GallerySlider />
    </section>
  )
}
