import React from "react"

export default function Downloads() {
  return (
    <section id="downloads" className="downloads">
      <div className="wrapper downloads__wrapper">
        <p className="downloads__text">
          Download the{" "}
          <a
            href="http://uniline.com.au/Product/Detail/33/Dawn-Blockout-Fabric/17/#product_tabs"
            target="_blank"
            rel="noopener noreferrer"
          >
            Dawn&nbsp;Retail&nbsp;Brochure
          </a>{" "}
          and{" "}
          <a
            href="http://uniline.com.au/Product/Detail/33/Dawn-Blockout-Fabric/17/#product_tabs"
            target="_blank"
            rel="noopener noreferrer"
          >
            Interactive&nbsp;Sales&nbsp;Presentation
          </a>{" "}
          here.
        </p>
      </div>
    </section>
  )
}
