import React from "react"

import logo from "../images/dawn-fabrics-logo--white.svg"

export default function Footer() {
  return (
    <footer id="footer" className="site-footer">
      <div className="wrapper">
        <div className="site-footer__brand">
          <img src={logo} alt="Dawn Fabrics logo" />
        </div>
        <div className="site-footer__contact">
          <p>
            Free Call (AUS):
            <br />
            <a href="tel:1800 777 331">1800 777 331</a>
          </p>
          <p>
            Free Call (NZ):
            <br />
            <a href="tel:0800 700 801">0800 700 801</a>
          </p>
        </div>
        <p className="site-footer__copyright">
          © {new Date().getFullYear()} Dawn Fabrics
        </p>
        {/* <button>Open</button> */}
      </div>
      <p className="back-to-top__btn">
        <a href="#top">
          <span className="screen-reader-text">Back to top</span>
          <svg
            className="icon icon__chevron-up"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
          >
            <title>chevron-up</title>
            <path d="M15.484 12.452c-0.436 0.446-1.043 0.481-1.576 0l-3.908-3.747-3.908 3.747c-0.533 0.481-1.141 0.446-1.574 0-0.436-0.445-0.408-1.197 0-1.615 0.406-0.418 4.695-4.502 4.695-4.502 0.217-0.223 0.502-0.335 0.787-0.335s0.57 0.112 0.789 0.335c0 0 4.287 4.084 4.695 4.502s0.436 1.17 0 1.615z" />
          </svg>
        </a>
      </p>
    </footer>
  )
}
