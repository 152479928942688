import React from "react"

import AboutBanner from "./about/AboutBanner"
import AboutUsImg from "./about/AboutUsImg"
import OurHistoryImg from "./about/OurHistoryImg"

export default function About() {
  return (
    <section className="about-overview">
      <div className="about-banner">
        <AboutBanner />
        <p className="about-banner__title main-heading">
          A long tradition of quality
        </p>
      </div>
      <div className="wrapper">
        <article id="about" className="about">
          <AboutUsImg />
          <div className="about__text">
            <h2 className="section-title">About Us</h2>
            <p>
              Uniline Aust. Ltd is an Australian owned and operated company
              based in Queensland. We have a long history of providing quality
              blind fabrics for over a quarter of a century. Dawn is our
              flagship blockout with unsurpassed quality.
            </p>
            <p>
              In our Quality Control department we test each batch of material
              to ensure that our fabrics meet the highest standards, and it’s
              that dedication to quality that has allowed us to offer the
              longest fabric warranty in Australia & New Zealand.
            </p>
          </div>
        </article>
        <article id="history" className="history">
          <OurHistoryImg />
          <div className="history__text">
            <h2 className="section-title">Our History</h2>
            <p>
              In the early 90s, Dawn was the first Australian blockout blind
              fabric designed and made in Australia. Like all good inventions,
              the birth of Dawn was built out of a customer need. Australia is a
              harsh environment, and there was a need for a long lasting
              blockout blind that could handle the severe Aussie sun. In the 25+
              years since, hundreds of thousands Dawn blockout blinds across
              Australia and New Zealand have been installed and it is this
              experience and trust in our product that allows us to offer the
              longest fabric warranty in Australia and New Zealand.
            </p>
            <p>
              If you buy a Dawn blockout blind, you can be assured that it is
              the very best blockout blind money can buy.
            </p>
          </div>
        </article>
      </div>
    </section>
  )
}
