import React from "react"

import SwatchList from "./swatches/SwatchList"

export default function Swatches() {
  return (
    <section id="swatches" className="swatches">
      <h2 className="section-title swatches__title">
        With over 50 colours available, choose the perfect colour for your home.
      </h2>
      <SwatchList />
      <p className="swatches__cta-btn">
        <a
          className="cta-btn cta-btn--alt"
          href=" http://uniline.com.au/Product/FabricSwatch"
          target="_blank"
          rel="noopener noreferrer"
        >
          Request a free colour swatch
          <svg
            className="cta-btn__icon icon"
            width="20"
            height="20"
            viewBox="0 0 20 20"
          >
            <title>chevron-with-circle-right</title>
            <path d="M11 10l-2.302-2.506c-0.196-0.198-0.196-0.519 0-0.718 0.196-0.197 0.515-0.197 0.71 0l2.807 2.864c0.196 0.199 0.196 0.52 0 0.717l-2.807 2.864c-0.195 0.199-0.514 0.198-0.71 0-0.196-0.197-0.196-0.518 0-0.717l2.302-2.504zM10 0.4c5.302 0 9.6 4.298 9.6 9.6 0 5.303-4.298 9.6-9.6 9.6s-9.6-4.297-9.6-9.6c0-5.302 4.298-9.6 9.6-9.6zM10 18.354c4.613 0 8.354-3.74 8.354-8.354s-3.741-8.354-8.354-8.354c-4.615 0-8.354 3.74-8.354 8.354s3.739 8.354 8.354 8.354z" />
          </svg>
        </a>
      </p>
    </section>
  )
}
