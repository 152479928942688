import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const HeroImg = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(
          relativePath: { eq: "dawn-fabrics-hero01.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Img
        className="hero__img"
        fluid={data.placeholderImage.childImageSharp.fluid}
      />
    )}
  />
)
export default HeroImg
